class Mk {
    constructor() {
        $(() => {
            this.rem();
            this.nav();
        });
    }
    setHtmlFontSize(html, max = 1000, min = 375) {
        let _w = document.documentElement.clientWidth;
        if (_w < min) {
            html.style.fontSize = '50px';
        } else if (_w > max) {
            html.style.fontSize = '100px';
        } else {
            html.style.fontSize = ( (_w - min) / (max - min) + 1 ) * 50 + 'px';
        }
    }
    rem() {
        let html = document.getElementsByTagName('html')[0];
        this.setHtmlFontSize(html);
        window.onresize = this.debounce(()=>this.setHtmlFontSize(html));
    }
    debounce(fn, times = 300) {
        let st = Date.now(),
            timer;
        return function() {
            let et = Date.now();
            clearTimeout(timer);
            if (et - st < times) {
                timer = setTimeout(fn, times);
            } else {
                st = et;
                fn();
            }
        }
    }
    nav() {
        let nav = $('#nav_wap');
        $('#nav_wap_open').on('click', function () {
            nav.show();
        });
        $('#nav_wap_close').on('click', function () {
            nav.hide();
        });
    }
}

window.mk = new Mk();
